/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzVjMjI4MiIsIj9lcjwjY2ViZGRhIiwiO2VyPCM0MTE0NjV$LCIlPmBePCM3MmJlNDQiLCI~ZXI8I2Q1ZWNjNyIsIjtlcjwjNTVhNzJkfiwid2Fybj5gXjwjYjAwMDIwIiwiP2VyPCNlN2IzYmMiLCI7ZXI8Izk3MDAxM34sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEyfQ==
*/

@use "@angular/material" as mat;

// Include the common styles for Angular Material.
// Fonts
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

$fontConfig: (
  display-4:
    mat.define-typography-level(112px, 112px, 300, "Poppins", -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, "Poppins", -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, "Poppins", 0em),
  display-1: mat.define-typography-level(34px, 40px, 400, "Poppins", 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, "Poppins", 0em),
  title: mat.define-typography-level(20px, 32px, 500, "Poppins", 0.0075em),
  subheading-2:
    mat.define-typography-level(16px, 28px, 400, "Poppins", 0.0094em),
  subheading-1:
    mat.define-typography-level(15px, 24px, 500, "Poppins", 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, "Poppins", 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, "Poppins", 0.0179em),
  button: mat.define-typography-level(12px, 16px, 500, "Poppins", 1.25px),
  caption: mat.define-typography-level(12px, 20px, 400, "Poppins", 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, "Poppins", 0.0179em),
);

// Foreground Elements

// Light Theme Text
$dark-text: #312e60;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

body {
  --dark-accent-text-color: $dark-accent-text;
}

$mat-light-theme-foreground: (
  base: $dark-text,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: $dark-text,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: $dark-text,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat.core($fontConfig);

// Theme Config

body {
  --primary-color: #312e60;
  --primary-lighter-color: #c1c0cf;
  --primary-darker-color: #312e60;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
  color: #312e60;
}
$mat-primary: (
  main: #312e60,
  lighter: #c1c0cf,
  darker: #312e60,
  200: #312e60,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #ec8c2e;
  --accent-lighter-color: #f9ddc0;
  --accent-darker-color: #ec8c2e;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}
$mat-accent: (
  main: #ec8c2e,
  lighter: #f9ddc0,
  darker: #ec8c2e,
  200: #ec8c2e,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #b00020;
  --warn-lighter-color: #e7b3bc;
  --warn-darker-color: #970013;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #b00020,
  lighter: #e7b3bc,
  darker: #970013,
  200: #b00020,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

$iobase-terre: (
  main: #caa39e,
  lighter: #e5d9ce,
  darker: #caa39e,
  200: #caa39e,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

body {
  --iobase-terre-color: #caa39e;
  --iobase-terre-lighter-color: #e5d9ce;
}

$iobase-yellow: (
  main: #f7b500,
  lighter: #fde9b3,
  darker: #f39d00,
  200: #f7b500,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
$theme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: false,
  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background,
  iobase-terre: $iobase-terre,
  iobase-yellow: $iobase-yellow,
);
$altTheme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: true,
  foreground: $mat-dark-theme-foreground,
  background: $mat-dark-theme-background,
  iobase-terre: $iobase-terre,
  iobase-yellow: $iobase-yellow,
);

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
  @include mat.all-component-themes($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
  .mat-badge-content {
    font-family: "Poppins";
  }
}

.mat-button-wrapper {
  text-transform: uppercase !important;
  letter-spacing: 1.25px;
  vertical-align: middle;
  line-height: 16px;
  mat-icon {
    margin-bottom: 3px;
  }
}

.mat-overline {
  font-family: Poppins;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

tr.mat-header-row,
mat-header-cell {
  background-color: mat.get-color-from-palette($theme-primary, darker);
}

.mat-header-cell {
  color: $light-primary-text;
}

.mat-mini-fab {
  transform: scale(0.8) !important;
}

.mat-title,
.mat-subheading-2,
.mat-subheading-1,
.mat-headline,
.mat-card-title,
.text-accent,
h1,
h2,
h3,
h4 {
  color: mat.get-color-from-palette($theme-accent, main);
}

.cancel-button .mat-button-wrapper {
  color: mat.get-color-from-palette($theme-primary, main);
}

.text-primary {
  color: mat.get-color-from-palette($theme-primary, main);
}

.text-warn {
  color: mat.get-color-from-palette($theme-warn, main);
}

.text-io-base-terre {
  color: mat.get-color-from-palette($iobase-terre, main);
}

.text-io-base-terre-lighter {
  color: mat.get-color-from-palette($iobase-terre, lighter);
}

.mat-snack-bar-container {
  background: mat.get-color-from-palette($iobase-terre, main);
  color: $light-text;

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $light-text;
  }
}

.mat-dialog-actions {
  justify-content: end;
}

.mat-expansion-panel-header {
  font-weight: 400 !important;
}

th.text-center {
  text-align: center !important;
}

.mat-datepicker-content {
  height: 100%;
}