// Colors
.iobase-accent {
  color: $iobase-accent;
}

.iobase-second {
  color: $iobase-second;
}

.green {
  color: $green;
}

.green-apple {
  color: $green-apple;
}

.green-apple-light {
  color: $green-apple-light;
}

.blue-deep {
  color: $blue-deep;
}

.blue-sky {
  color: $blue-sky;
}

.blue-light {
  color: $blue-light;
}

.blue-lighter {
  color: $blue-lighter;
}

.purple {
  color: $purple;
}

.purple-flashy {
  color: $purple-flashy;
}

.purple-light {
  color: $purple-light;
}

.purple-lighter {
  color: $purple-lighter;
}

.purple-separator {
  color: $purple-separator;
}

.warm-purple {
  color: $warm-purple;
}

.fushia {
  color: $fushia;
}

.sanguine-orange {
  color: $sanguine-orange;
}

.orange {
  color: $orange;
}

.yellow-sun {
  color: $yellow-sun;
}

.black {
  color: $black;
}

.grey-dark {
  color: $grey-dark;
}

.grey-deep {
  color: $grey-deep;
}

.grey {
  color: $grey;
}

.grey-light {
  color: $grey-light;
}

.grey-lighter {
  color: $grey-lighter;
}

.grey-ultra {
  color: $grey-ultra;
}

.white {
  color: $white;
}

.grey-bg {
  color: $grey-bg;
}

// Background
.bg-green {
  background-color: $green;
}

.bg-green-apple {
  background-color: $green-apple;
}

.bg-green-apple-light {
  background-color: $green-apple-light;
}

.bg-blue-deep {
  background-color: $blue-deep;
}

.bg-blue-sky {
  background-color: $blue-sky;
}

.bg-blue-light {
  background-color: $blue-light;
}

.bg-blue-lighter {
  background-color: $blue-lighter;
}

.bg-purple {
  background-color: $purple;
}

.bg-purple-flashy {
  background-color: $purple-flashy;
}

.bg-purple-light {
  background-color: $purple-light;
}

.bg-purple-lighter {
  background-color: $purple-lighter;
}

.bg-purple-separator {
  background-color: $purple-separator;
}

.bg-warm-purple {
  background-color: $warm-purple;
}

.bg-fushia {
  background-color: $fushia;
}

.bg-sanguine-orange {
  background-color: $sanguine-orange;
}

.bg-orange {
  background-color: $orange;
}

.bg-yellow-sun {
  background-color: $yellow-sun;
}

.bg-black {
  background-color: $black;
}

.bg-grey-dark {
  background-color: $grey-dark;
}

.bg-grey-deep {
  background-color: $grey-deep;
}

.bg-grey {
  background-color: $grey;
}

.bg-grey-light {
  background-color: $grey-light;
}

.bg-grey-lighter {
  background-color: $grey-lighter;
}

.bg-grey-ultra {
  background-color: $grey-ultra;
}

.bg-white {
  background-color: $white;
}

.bg-grey-bg {
  background-color: $grey-bg;
}

.gray-svg {
  filter: invert(45%) sepia(6%) saturate(491%) hue-rotate(173deg)
    brightness(95%) contrast(86%);
}

.purple-svg {
  filter: invert(18%) sepia(34%) saturate(3819%) hue-rotate(263deg)
    brightness(95%) contrast(101%);
}

.blue-svg {
  filter: invert(48%) sepia(50%) saturate(3266%) hue-rotate(165deg)
    brightness(96%) contrast(101%);
}

.fushia-svg {
  filter: invert(24%) sepia(77%) saturate(3095%) hue-rotate(326deg)
    brightness(88%) contrast(102%);
}

.yellow-sun-svg {
  filter: invert(74%) sepia(96%) saturate(2169%) hue-rotate(2deg)
    brightness(99%) contrast(103%);
}

:root {
  --iobase-accent: #caa39e;
  --iobase-second: #ec8c2e;
  --green: #4ba37a;
  --green-apple: #7baf43;
  --green-apple-light: #80b13d;
  --blue-deep: #00a2dd;
  --blue-sky: #2d99ff;
  --blue-light: #83cfff;
  --blue-lighter: #bbe4fa;
  --purple: #312e60;
  --purple-flashy: #6236ff;
  --purple-light: #5c2282;
  --purple-lighter: #830dd0;
  --purple-separator: #7a779d;
  --fushia: #e31d5d;
  --sanguine-orange: #eb3712;
  --orange: #fa6400;
  --yellow-sun: #f7b500;
  --black: #080808;
  --grey-dark: #38373a;
  --grey-deep: #6d7278;
  --grey: #9f9f9f;
  --grey-light: #d0d0d0;
  --grey-lighter: #f0f0f0;
  --grey-ultra: #fafafa;
  --white: #ffffff;
  --grey-bg: #f5f6fa;
  --warm-purple: #7a2d76;
}
