
$iobase-accent: #caa39e;
$iobase-second: #ec8c2e;

$green: #4ba37a;
$green-apple: #7baf43;
$green-apple-light: #80b13d;
$blue-deep: #00a2dd;
$blue-sky: #2d99ff;
$blue-light: #83cfff;
$blue-lighter: #bbe4fa;
$purple: #312e60;
$purple-flashy: #6236ff;
$purple-light: #5c2282;
$purple-lighter: #830dd0;
$purple-separator: #7a779d;
$fushia: #e31d5d;
$sanguine-orange: #eb3712;
$orange: #fa6400;
$yellow-sun: #f7b500;
$black: #080808;
$grey-dark: #38373a;
$grey-deep: #6d7278;
$grey: #9f9f9f;
$grey-light: #d0d0d0;
$grey-lighter: #f0f0f0;
$grey-ultra: #fafafa;
$white: #ffffff;
$grey-bg: #f5f6fa;
$warm-purple: #7a2d76;
