.pointer {
  cursor: pointer;
}

.pointer-help {
  cursor: help;
}

.bold {
  font-family: 'HurmeGeometricSans4Bold', sans-serif;
}

.semi-bold {
  font-family: 'HurmeGeometricSans4SemiBold', sans-serif;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.text-overline {
  text-decoration: overline;
}

.break-word {
  word-break: break-word;
}

.no-wrap {
  white-space: nowrap;
}
