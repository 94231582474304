/* You can add global styles to this file, and also import other style files */
// @import '@angular/material/prebuilt-themes/indigo-pink';
@import './styles/variables';
@import './styles/colors';
@import './styles/palettes';
@import './styles/theme';
@import './styles/font-size';
@import './styles/font-style';
@import './styles/flex';
@import './styles/spacing';
@import './styles/card';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-form-field-wrapper {
    position: relative;
    width: 75%!important;
    margin-left: 10%!important;
}

.mat-form-field.filtered {
    background-color: var(--purple-light);
    border-radius: 4px;
    line-height: 34px;
    color: var(--white);
    text-align: center;
    font-family: 'HurmeGeometricSans4SemiBold', sans-serif;

    .mat-select-value {
      color: var(--white);
    }

    .mat-select-arrow {
      box-sizing: border-box !important;
      height: 10px !important;
      width: 10px !important;
      margin-top: -4px !important;
      margin-right: 10px !important;
      border-style: solid !important;
      border-color: var(--white) !important;
      border-width: 0px 1px 1px 0px !important;
      transform: rotate(45deg) !important;
    }

    .mat-select-placeholder {
      color: var(--white);
    }
  }

.snackbar-error {
  background: var(--sanguine-orange);
  color: white;

  .mat-simple-snackbar-action {
    color: white;
  }
}
