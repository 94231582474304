.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.j-start {
  justify-content: flex-start;
}

.j-between {
  justify-content: space-between;
}

.j-around {
  justify-content: space-around;
}

.j-center {
  justify-content: center;
}

.j-end {
  justify-content: flex-end;
}

.a-center {
  align-items: center;
}

.as-end{
  align-self: flex-end;
}

.as-center {
  align-self: center;
}

.flex-grow {
  flex-grow: 1;
}
