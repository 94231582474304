// margins & paddings
$spacer: 1rem;
$spacers: (0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 4.5),
  7: ($spacer * 6),
  8: ($spacer * 7.5),
  9: ($spacer * 8.5),
  10: ($spacer * 9));

@each $prop,
$abbrev in (margin: m, padding: p) {

  @each $size,
  $length in $spacers {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }

    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }

    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }

    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }

    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}

@for $i from 1 through 50 {
  .flex-g-#{$i} {
    gap: $i + px;
  }
}
